<template>
  <div id="base-container">
    <navbar :content-ref="$refs.content" />
    <div id="app">
      <router-view ref="content" />
      <cookie-law
        information-button-link="/impress"
        close-button-variant="success"
        information-button-text="Mehr Informationen"
        close-button-text="Okay!"
        theme="gray-transparent"
      >
        <p slot="message">
          Diese Website benutzt Cookies. Durch die Nutzung des Angebots akzeptieren Sie unsere Datenschutzbedingungen.
        </p>
      </cookie-law>
    </div>
    <foot />
    <noscript><p><img src="https://piwik.giz.berlin/piwik.php?idsite=13&amp;rec=1" style="border:0;" alt="" /></p></noscript>
  </div>
</template>

<script>
  import CookieLaw from 'vue-bootstrap-cookie-law';

  import Navbar from './components/Navbar.vue';
  import Foot from "./components/Foot";
  export default {
    name: 'App',
    components: {
      'foot': Foot,
      'navbar': Navbar,
      'cookie-law': CookieLaw,
    }
  }
</script>

<style lang="scss">
  @import 'style/bootstrap-vars';
  @import '~bootstrap/scss/bootstrap';
  @import '~animate.css/animate.css';

  $roboto-font-path: '~roboto-fontface/fonts';

  @import '~roboto-fontface/css/roboto/sass/roboto-fontface';

  #app {
    overflow: hidden;
  }

  h2 {
    margin-top: 10px;
  }
</style>
