<template>
  <b-navbar
    toggleable="md"
    type="dark"
    class="navbar-dark sticky-top bg-dark"
  >
    <b-navbar-toggle target="nav_collapse" />
    <b-navbar-brand
      to="/"
      class="navbar-brand"
    >
      <img
        id="navbar-logo"
        src="../assets/images/logo-small-white.svg"
      >
      <span class="d-none d-sm2-inline" />
    </b-navbar-brand>

    <b-collapse
      id="nav_collapse"
      is-nav
    >
      <b-navbar-nav
        v-b-scrollspy="20"
        @click="scrollIntoMainView"
      >
        <b-nav-item href="#about">
          <icon
            name="home"
            scale="1.2"
            class="symbol"
          /> Projekt
        </b-nav-item>
        <b-nav-item
          href="#news"
          @click="scrollIntoMainView"
        >
          <icon
            name="newspaper"
            scale="1"
            class="symbol"
          /> Aktuelles
        </b-nav-item>
        <b-nav-item
          href="#who"
          @click="scrollIntoMainView"
        >
          <icon
            name="users"
            scale="1"
            class="symbol"
          /> Team
        </b-nav-item>
        <b-nav-item
          href="#map"
          @click="scrollIntoMainView"
        >
          <icon
            name="map"
            scale="1"
            class="symbol"
          /> Karte
        </b-nav-item>
        <b-nav-item
          href="#coordinators"
          @click="scrollIntoMainView"
        >
          <icon
            name="user-friends"
            scale="1"
            class="symbol"
          /> Koordinatoren
        </b-nav-item>
        <!--b-nav-item href="#work" @click="scrollIntoMainView">
          <icon
            name="cogs"
            scale="1"
            class="symbol"
          /> Arbeitsmittel
        </b-nav-item-->
        <b-nav-item
          href="#press"
          @click="scrollIntoMainView"
        >
          <icon
            name="bullhorn"
            scale="1"
            class="symbol"
          /> Presse
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/impress">
          Impressum
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
  export default {
    name: 'Navbar',
    props: [
      'contentRef' // eslint-disable-line vue/require-prop-types
    ],
    methods: {
      scrollIntoMainView: function () {
        if (this.$router.currentRoute.name !== 'home') {
          this.$router.push({name: 'Home'})
        }
      }
    }
  }
</script>


<style lang="scss" scoped>
  $grid-breakpoints: (
    xs: 0,
    sm2: 400px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  );

  @import '../style/bootstrap-component-include';
  @import '~bootstrap/scss/bootstrap-grid';

  .symbol {
    margin-top: -5px;
    vertical-align: middle;
  }

  .sticky-top {
    z-index: 1;
  }

  #navbar-logo {
    margin-top: -5px;
    height: 30px;
  }
</style>
