import Vue from 'vue'
import 'vue-awesome/icons/map'
import 'vue-awesome/icons/users'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/newspaper'
import 'vue-awesome/icons/user-friends'
import 'vue-awesome/icons/bullhorn'
import 'vue-awesome/icons/phone'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/chalkboard-teacher'
import 'vue-awesome/icons/handshake'
import 'vue-awesome/icons/comments'
import Icon from 'vue-awesome/components/Icon'
import './plugins/bootstrap-vue'
import VueMatomo from 'vue-matomo'
import App from './App.vue'
import router from './router'
import store from './store'

//import * as Sentry from '@sentry/browser'

Vue.config.productionTip = false

Vue.component('icon', Icon)

if (process.env.NODE_ENV !== 'development') {
  /*Sentry.init({
    dsn: 'https://7450412ee33b4f3388f7ee41d3645686@sentry.giz.berlin/8',
    integrations: [new Sentry.Integrations.Vue({ Vue })]
  })*/

  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://piwik.giz.berlin',
    siteId: 13,

    // Changes the default .js and .php endpoint's filename
    // Default: 'piwik'
    trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Enables automatically registering pageviews on the router
    router: router
  })
}

Vue.directive('set-class-on-scroll-vertical', {
  inViewport (el) {
    const rect = el.getBoundingClientRect()

    const offsetVertical = window.innerHeight / 4

    return (
        rect.top > offsetVertical &&
        rect.top < (window.innerHeight - offsetVertical)
    ) || (
        rect.bottom > offsetVertical &&
        rect.bottom < (window.innerHeight - offsetVertical)
    )
  },

  bind (el, binding) {
    el.$onScroll = function () {
      if (binding.def.inViewport(el)) {
        el.classList.add(binding.value)
        el.classList.remove('invisible')
        binding.def.unbind(el, binding)
      }
    }
    document.addEventListener('scroll', el.$onScroll)
  },

  inserted (el) {
    el.$onScroll()
  },

  created (el) {
    el.$onScroll()
  },

  unbind (el) {
    document.removeEventListener('scroll', el.$onScroll)
    delete el.$onScroll
  }
})

Vue.directive('call-on-scroll-vertical', {
  inViewport (el) {
    const rect = el.getBoundingClientRect()

    const offsetVertical = window.innerHeight / 4

    return (
        rect.top > offsetVertical &&
        rect.top < (window.innerHeight - offsetVertical)
    ) || (
        rect.bottom > offsetVertical &&
        rect.bottom < (window.innerHeight - offsetVertical)
    )
  },

  bind (el, binding) {
    el.$onScroll = function () {
      if (binding.def.inViewport(el)) {
        binding.value()
        binding.def.unbind(el, binding)
      }
    }
    document.addEventListener('scroll', el.$onScroll)
  },

  inserted (el) {
    el.$onScroll()
  },

  created (el) {
    el.$onScroll()
  },

  unbind (el) {
    document.removeEventListener('scroll', el.$onScroll)
    delete el.$onScroll
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
