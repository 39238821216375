<template>
  <footer>
    <div
      id="footer-pre"
      class="container-fluid"
    />

    <b-container>
      <b-row>
        <b-col md="3">
          <p class="mt-4 text-center">
            <a href="https://bmbf.de">
              <img
                class="text-center mt-3"
                src="../assets/images/bmbf.svg"
                height="130px"
              >
            </a>
          </p>
        </b-col>
        <b-col md="3">
          <p class="mt-4 text-center">
            <a href="https://bmbf.de">
              <img
                class="text-center mt-3"
                src="../assets/images/alphabund_mid.jpeg"
                width="100%"
              >
            </a>
          </p>
        </b-col>
        <b-col md="3">
          <p class="text-muted text-center">
            KASA ist ein Projekt der <a href="https://giz.berlin">
              GIZ gGmbH
            </a>
            <a href="https://giz.berlin">
              <img
                class="text-center mt-3"
                src="../assets/images/giz.svg"
                height="150px"
              >
            </a>
          </p>
        </b-col>
        <b-col md="3">
          <p class="text-muted text-justify text-small mt-5">
            Dieses Vorhaben wird mit Mitteln des Bundesministeriums für Bildung und Forschung unter dem Förderkennzeichen W1451KASA gefördert. Die Verantwortung für den Inhalt dieser Veröffentlichung liegt beim Autor.
          </p>
          <p class="text-muted text-right text-small mt-5">
            &copy; GIZ gGmbH {{ year }} | <router-link to="/impress">
              Impressum
            </router-link>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
  export default {
    name: 'Foot',
    computed: {
      year: () => {
        return new Date().getFullYear()
      }
    }
  }
</script>


<style lang="scss" scoped>
  @import '../style/bootstrap-component-include';

  #footer-pre {
    border-top: 1px dashed $dark;
    padding-bottom: 20px;
  }

  .text-small {
    font-size: 70%;
  }
</style>
